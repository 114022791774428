import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./ClientSection.scss"
import {
  TrackHero,
  OhmConnect,
  Strada,
  Pixeleto,
  Vectorscient,
  DriverBee,
  Aimexperts,
  IBD,
  Ugami,
  Hero,
  Rating,
  Energy,
} from "../SVGs"

function ClientSection() {
  const imgs = [
    <TrackHero />,
    <OhmConnect />,
    <Strada />,
    <Pixeleto />,
    <Vectorscient />,
    <DriverBee />,
    <Aimexperts />,
    <IBD />,
    <Ugami />,
    <Hero />,
    <Rating />,
    <Energy />,
  ]

  return (
    <div className="client-section-wrapper-about">
      <Container>
        <Row className="img-container mb-3" style={{ rowGap: "1.5rem" }}>
          {imgs.map((item, i) => (
            <Col
              md={3}
              sm={6}
              xs={12}
              className="d-flex justify-content-center mb-3"
              key={i}
            >
              <div>{item}</div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ClientSection
